import modalReducer from "features/modalSlice";
import accountReducer from "features/accountSlice";
import productReducer from "features/productSlice";
import customerReducer from "features/customerSlice";
import dashboardReducer from "features/dashboardSlice";

export const reducer = {
  accountReducer,
  productReducer,
  modalReducer,
  customerReducer,
  dashboardReducer,
};
