export const BASE_URL = process.env.REACT_APP_BASE_URL;

const admin = "admin";
const login = "auth/login";
const devices = "Devices";
const customers = "Customers";
const dashboards = "dashboards";

export default {
  BASE_URL,
  admin,
  login,
  devices,
  customers,
  dashboards,
};
