import { createSlice } from "@reduxjs/toolkit";

interface InitState {
  loginning: boolean;
  registering: boolean;
  account: any;
}

const initialState = {
  loginning: false,
  registering: false,
  account: {},
} as InitState;

export const accountSlice = createSlice({
  name: "accountSlice",
  initialState,
  reducers: {
    // eslint-disable-next-line
    loginRequest: (state, __) => {
      state.loginning = true;
    },
    loginSuccess: (state, action) => {
      state.loginning = false;
      state.account = action.payload;
    },
    loginFail: (state) => {
      state.loginning = false;
    },
    // eslint-disable-next-line
    registerRequest: (state, __) => {
      state.registering = true;
    },
    // eslint-disable-next-line
    registerSuccess: (state, __) => {
      state.registering = false;
    },
    registerFail: (state) => {
      state.registering = false;
    },
  },
});
export const {
  loginRequest,
  loginSuccess,
  loginFail,
  registerRequest,
  registerSuccess,
  registerFail,
} = accountSlice.actions;
export default accountSlice.reducer;
