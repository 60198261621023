import React, { useEffect } from "react";
import EChart from "./components/EChart";
import LineChart from "./components/LineChart";
import { Card, Col, Row } from "antd";
import Title from "antd/lib/typography/Title";
import { Content } from "antd/lib/layout/layout";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { PayloadType } from "type";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { getDashboardRequest } from "features/dashboardSlice";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { dashboard } = useSelector(
    (state: RootState) => state.dashboardReducer
  );
  const createPerMonth = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      width="16"
      viewBox="0 0 448 512"
      key={0}
      fill="white"
    >
      <path d="M96 32V64H48C21.5 64 0 85.5 0 112v48H448V112c0-26.5-21.5-48-48-48H352V32c0-17.7-14.3-32-32-32s-32 14.3-32 32V64H160V32c0-17.7-14.3-32-32-32S96 14.3 96 32zM448 192H0V464c0 26.5 21.5 48 48 48H400c26.5 0 48-21.5 48-48V192zM224 248c13.3 0 24 10.7 24 24v56h56c13.3 0 24 10.7 24 24s-10.7 24-24 24H248v56c0 13.3-10.7 24-24 24s-24-10.7-24-24V376H144c-13.3 0-24-10.7-24-24s10.7-24 24-24h56V272c0-13.3 10.7-24 24-24z" />
    </svg>,
  ];
  const allDevice = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      width="18"
      viewBox="0 0 512 512"
      key={0}
      fill="white"
    >
      <path d="M64 32C28.7 32 0 60.7 0 96v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm280 72a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm48 24a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zM64 288c-35.3 0-64 28.7-64 64v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V352c0-35.3-28.7-64-64-64H64zm280 72a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm56 24a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z" />
    </svg>,
  ];
  const allActive = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      width="20"
      viewBox="0 0 576 512"
      key={0}
      fill="white"
    >
      <path d="M96 80c0-26.5 21.5-48 48-48H432c26.5 0 48 21.5 48 48V384H96V80zm313 47c-9.4-9.4-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L409 161c9.4-9.4 9.4-24.6 0-33.9zM0 336c0-26.5 21.5-48 48-48H64V416H512V288h16c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V336z" />
    </svg>,
  ];
  const activePerMonth = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      width="16"
      viewBox="0 0 448 512"
      key={0}
      fill="white"
    >
      <path d="M128 0c13.3 0 24 10.7 24 24V64H296V24c0-13.3 10.7-24 24-24s24 10.7 24 24V64h40c35.3 0 64 28.7 64 64v16 48V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192 144 128C0 92.7 28.7 64 64 64h40V24c0-13.3 10.7-24 24-24zM400 192H48V448c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V192zM329 297L217 409c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47 95-95c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
    </svg>,
  ];
  const count = [
    {
      today: "Số lượng thiết bị",
      title: dashboard?.numberDevice ?? 0,
      persent: "+0%",
      icon: allDevice,
      bnb: "bnb2",
    },
    {
      today: "Đã kích hoạt",
      title: dashboard?.numberDeviceActived ?? 0,
      persent: "0%",
      icon: allActive,
      bnb: "redtext",
    },
    {
      today: "Đã kích hoạt/tháng",
      title: dashboard?.numberDeviceActivedInMonth ?? 0,
      persent: "0%",
      icon: activePerMonth,
      bnb: "bnb2",
    },
    {
      today: "Đã tạo/tháng",
      title: dashboard?.numberCreatedAtInMont ?? 0,
      persent: "0%",
      icon: createPerMonth,
      bnb: "bnb2",
    },
  ];

  console.log(dashboard);

  const handleGetDashboard = () => {
    const payload: PayloadType = {
      query: queryString.stringify({}),
    };
    dispatch(getDashboardRequest(payload));
  };

  useEffect(() => {
    handleGetDashboard();
  }, []);
  return (
    <Content
      className="custom-layout-content"
      style={{
        marginLeft: 24,
        marginTop: 16,
        marginRight: 24,
      }}
    >
      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {count.map((c, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              lg={6}
              xl={6}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>{c.today}</span>
                      <Title level={3}>
                        {c.title}
                        {/* <small className={c.bnb}>{c.persent}</small> */}
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">{c.icon}</div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <EChart
                realTimeChartsData={dashboard?.realTimeChartsData ?? null}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={14} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <LineChart
                deviceCreatedInYears={dashboard?.deviceCreatedInYears ?? null}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default Dashboard;
