/**
 * Màn hình quản trị admin
 */
import { memo, useEffect } from "react";
import { Layout, Typography, Space, Button, Table, Popconfirm } from "antd";
import _ from "lodash";
import { PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { momentTzUnix } from "utils";

import CONSTANT from "constant";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "store";
import { ModalInfoType, PayloadType } from "type";
import queryString from "query-string";
import { getCustomersRequest, setQueryRequest } from "features/customerSlice";
import { setModal } from "features/modalSlice";
import CCustomer from "./components/CCustomer";

const { Title } = Typography;
const { Content } = Layout;

type Column = {
  deviceName: string;
  seriNumber: string;
  activeTime: number;
  expTime: number;
  isActive: boolean;
};

function Customer() {
  const dispatch = useDispatch();
  const { query, customers, gettingCustomers } = useSelector(
    (state: RootState) => state.customerReducer
  );

  const columns: ColumnsType<Column> = [
    {
      title: "Số điện thoại",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Tên khách hàng",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Giới tính",
      dataIndex: "gender",
      key: "gender",
      render: (cell) => {
        return cell == 0 ? "Name" : "Nữ";
      },
    },
    {
      title: "Năm sinh",
      dataIndex: "yearOfBirth",
      key: "yearOfBirth",
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
    },
    // {
    //   title: "Hành động",
    //   dataIndex: "action",
    //   key: "action",
    //   align: "center",
    //   width: 300,
    //   render: (_, record: any) => {
    //     return (
    //       <Space>
    //         <a
    //           onClick={() => {
    //             const payload: ModalInfoType = {
    //               open: true,
    //               title: "Cập nhật thông tin khách hàng",
    //               content: <CCustomer record={record} />,
    //               size: 456,
    //               onClose: () => {
    //                 handleGetCustomers();
    //               },
    //             };
    //             dispatch(setModal(payload));
    //           }}
    //         >
    //           Cập nhật
    //         </a>
    //       </Space>
    //     );
    //   },
    // },
  ];

  const handleGetCustomers = () => {
    const payload: PayloadType = {
      query: queryString.stringify(query),
    };
    dispatch(getCustomersRequest(payload));
  };

  useEffect(() => {
    handleGetCustomers();
  }, [query]);

  return (
    <Content
      className="custom-layout-content background-content-layout"
      style={{
        marginLeft: 24,
        marginTop: 16,
        marginRight: 24,
      }}
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size={"large"}
      >
        <div className="space-between">
          <Title level={4}>Quản trị khách hàng</Title>
          <Space direction="horizontal" size={"middle"}></Space>
        </div>
        <>
          <div
            className="space-between"
            style={{
              marginTop: 12,
              marginBottom: 24,
            }}
          >
            <Title level={5}>Danh sách</Title>
            <Space className="custom-placeholder-select">
              <Button
                type="default"
                onClick={() => {
                  const payload: ModalInfoType = {
                    open: true,
                    title: "Thêm mới thông tin khách hàng",
                    content: <CCustomer />,
                    size: 624,
                    onClose: () => {
                      handleGetCustomers();
                    },
                  };
                  dispatch(setModal(payload));
                }}
                style={{
                  background: CONSTANT.COLORS.MAIN,
                  fontWeight: 400,
                  color: CONSTANT.COLORS.MAIN_TEXT_GREY,
                }}
              >
                <PlusOutlined />
                Thêm mới
              </Button>
            </Space>
          </div>
          <Table
            columns={columns}
            rowKey={"id"}
            dataSource={_.get(customers, "results", [])}
            loading={gettingCustomers}
            // rowClassName={(record: any) =>
            //   // record?.status === "active" ? "table-row-open" : "table-row-close"
            // }
            pagination={{
              total: _.get(customers, "totalResults", 0),
              current: query.page,
              pageSize: query.limit,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50"],
              showTotal: (total) => {
                return `Có tất cả ${total} bản ghi`;
              },
              onChange(page, pageSize) {
                dispatch(
                  setQueryRequest({
                    page,
                    limit: pageSize,
                  })
                );
              },
            }}
            scroll={{ x: 1300 }}
          />
        </>
      </Space>
    </Content>
  );
}

export default memo(Customer);
