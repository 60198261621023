const GENDER = {
  "1": "Nam",
  "0": "Nữ",
  "-1": "Khác",
};
const COLORS = {
  MAIN: "#2B51A4",
  MAIN_GREY: "#F6F6F6",
  MAIN_TEXT_GREY: "#F6F6F6",
};

const USER_SATTUS = [
  {
    label: "Hoạt động",
    value: "active",
  },
  {
    label: "Không hoạt động",
    value: "lock",
  },
];

export default {
  GENDER,
  COLORS,
  USER_SATTUS,
};
