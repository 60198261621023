import { Button, Form, Input, Select, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";

import _ from "lodash";
import { useDispatch } from "react-redux";
import { Customer, PayloadType, Product } from "type";
import * as yup from "yup";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { RootState } from "store";
import CONSTANT from "constant";
import {
  activeProductRequest,
  updateProductRequest,
} from "features/productSlice";
import { setModal } from "features/modalSlice";
import CustomLabelValue from "components/CustomLabelValue";
import { getCustomersRequest } from "features/customerSlice";

interface Props {
  record: Product;
}
function AProduct({ record }: Props) {
  const dispatch = useDispatch();
  const [form] = useForm();
  const { creatingProduct } = useSelector(
    (state: RootState) => state.productReducer
  );
  const { customers } = useSelector(
    (state: RootState) => state.customerReducer
  );
  const ltsCustomer = _.get(customers, "results", []).map((item: Customer) => ({
    label: item.phoneNumber,
    value: item.id,
    ...item,
  }));
  const [customerValue, setCustomerValue] = useState<Customer | null>(null);
  const modal = useSelector((state: any) => state.modalReducer);

  //validate
  const productSchema = yup.object().shape({
    secretCode: yup.string().required("Mã bảo mật không được để trống!"),
    phoneNumber: yup.string().required("Khách hàng không được để trống!"),
  });
  const yupSync = {
    async validator({ field }: any, value: any) {
      await productSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const initValues = {
    secretCode: "",
    phoneNumber: "",
  };

  console.log(customerValue);

  function handleActive(values: any) {
    if (!record?.id) return;
    const payload: PayloadType = {
      param: record.id,
      body: {
        seriNumber: record.seriNumber,
        phoneNumber: customerValue?.phoneNumber,
        customerName: customerValue?.name,
        customerAddress: customerValue?.address,
        yearOfBirth: customerValue?.yearOfBirth,
        secretCode: values.secretCode,
        gender: customerValue?.gender,
      },
      callback: {
        success() {
          notification.success({
            message: "Kích hoạt thiết bị thành công!",
          });
          resetForm();
          if (!!modal.onClose) {
            modal.onClose();
          }
          handleClose();
        },
        failed(errorMessage) {
          notification.error({
            message: "Kích hoạt thiết bị thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(activeProductRequest(payload));
  }

  const resetForm = () => {
    form.resetFields();
  };

  const handleClose = () => {
    dispatch(setModal({ open: false }));
  };

  const onChange = (__: any, option: any) => {
    setCustomerValue(option);
  };
  const onSearch = (value: string) => {
    handleGetCustomers(value);
  };

  const handleGetCustomers = (search?: string) => {
    const query: {
      limit: number;
      page: number;
      phoneNumber?: string;
    } = {
      limit: 10,
      page: 1,
    };
    if (!!search && search !== "") {
      query.phoneNumber = search;
    }
    const payload: PayloadType = {
      query: queryString.stringify(query),
    };
    dispatch(getCustomersRequest(payload));
  };

  useEffect(() => {
    handleGetCustomers();
  }, []);

  return (
    <Form
      labelCol={{ span: 16 }}
      layout="vertical"
      initialValues={initValues}
      labelAlign="left"
      form={form}
      onFinish={handleActive}
    >
      <div className="ant-modal-body">
        <CustomLabelValue
          hasMarginleft={false}
          alignX={64}
          label="Số Seri"
          value={_.get(record, "seriNumber", "")}
        />
        <Form.Item
          required
          label={"Mã bảo mật"}
          name={"secretCode"}
          rules={[yupSync]}
        >
          <Input placeholder="Nhập vào mã bảo mật" />
        </Form.Item>
        <Form.Item
          label={"Khách hàng"}
          name={"phoneNumber"}
          rules={[yupSync]}
          required
        >
          <Select
            placeholder="Chọn khách hàng"
            style={{ width: "100%" }}
            options={ltsCustomer as never[]}
            showSearch
            onChange={onChange}
            onSearch={onSearch}
          />
        </Form.Item>
      </div>
      <div
        className="ant-modal-footer"
        style={{ padding: "10px 0px", textAlign: "center" }}
      >
        <Button
          onClick={() => {
            handleClose();
          }}
          style={{ width: "48%" }}
        >
          Đóng
        </Button>
        <Button
          type="default"
          style={{
            background: CONSTANT.COLORS.MAIN,
            fontWeight: 400,
            color: CONSTANT.COLORS.MAIN_TEXT_GREY,
            width: "48%",
          }}
          loading={creatingProduct}
          htmlType="submit"
        >
          Kích hoạt
        </Button>
      </div>
    </Form>
  );
}
export default AProduct;
