import React from "react";
import Login from "pages/Login";
//icon
import {
  BoxPlotOutlined,
  UsergroupAddOutlined,
  LineChartOutlined,
} from "@ant-design/icons";

import Product from "pages/Product";
import Customer from "pages/Customer";
import Dashboard from "pages/Dashboard";

export interface RouteItem {
  hidden?: boolean; //VD: Ẩn các route cho màn thêm, sửa
  collapse?: boolean;
  state?: string; // các route sẽ nhớ và active lại khi f5: [state,path+name]
  name?: string;
  path?: string;
  icon?: any;
  component?: React.FunctionComponent;
  layout?: string;
  roles?: [];
  views?: RouteItem[]; // yêu cầu collapse = true
  miniName?: string;
}

// Route Auth
const routesA: RouteItem[] = [
  {
    collapse: true,
    name: "Auth",
    state: "authCollapse",
    views: [
      {
        path: "/login",
        name: "Đăng nhập",
        miniName: "L",
        component: Login,
        layout: "/auth",
        roles: [],
      },
    ],
  },
];
const routesSM: RouteItem[] = [
  {
    collapse: false,
    name: "Tổng quan",
    path: "/dashboard",
    icon: React.createElement(LineChartOutlined),
    layout: "/admin",
    component: Dashboard,
  },
  {
    collapse: false,
    name: "Quản lý sản phẩm",
    path: "/products",
    icon: React.createElement(BoxPlotOutlined),
    layout: "/admin",
    component: Product,
  },
  {
    collapse: false,
    name: "Quản lý khách hàng",
    path: "/customers",
    icon: React.createElement(UsergroupAddOutlined),
    layout: "/admin",
    component: Customer,
  },
];

export default {
  routesA,
  routesSM,
};
