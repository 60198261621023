import React from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";

// core components
import routes, { RouteItem } from "routes";

const Auth: React.FC<RouteComponentProps<any, StaticContext, unknown>> = () => {
  const location = useLocation();
  const mainContentRef = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
    if (mainContentRef.current) {
      mainContentRef.current.scrollTop = 0;
    }
    document.body.classList.add("bg-color");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.remove("bg-color");
    };
  });
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
    if (mainContentRef.current) {
      mainContentRef.current.scrollTop = 0;
    }
  }, [location]);
  const getRoutes = (routes: RouteItem[]): React.ReactNode => {
    return routes.map((prop: RouteItem, key: number) => {
      if (prop.collapse) {
        return getRoutes(prop.views ?? []);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content" ref={mainContentRef}>
        <Switch>
          {getRoutes(routes.routesA)}
          <Redirect from="/*" to="/auth/login" />
        </Switch>
      </div>
    </>
  );
};

export default Auth;
