import { createSlice } from "@reduxjs/toolkit";

interface Query {
  page: number;
  limit: number;
  customerName: string;
  seriNumber: string;
}
interface InitState {
  gettingProducts: boolean;
  creatingProduct: boolean;
  updatingProduct: boolean;
  deletingProduct: boolean;
  activingProduct: boolean;
  products: object | {};
  query: Query;
}

const initialState = {
  gettingProducts: false,
  creatingProduct: false,
  updatingProduct: false,
  deletingProduct: false,
  activingProduct: false,
  products: {},
  query: {
    page: 1,
    limit: 10,
    populate: "customerId",
    customerName: "",
    seriNumber: "",
  },
} as InitState;

export const productSlice = createSlice({
  name: "productSlice",
  initialState,
  reducers: {
    setQueryRequest: (state, action) => {
      state.query = action.payload;
    },
    // eslint-disable-next-line
    getProductsRequest: (state, __) => {
      state.gettingProducts = true;
    },
    getProductsSuccess: (state, action) => {
      state.gettingProducts = false;
      state.products = action.payload;
    },
    getProductsFail: (state) => {
      state.gettingProducts = false;
    },
    // eslint-disable-next-line
    createProductRequest: (state, __) => {
      state.creatingProduct = true;
    },
    // eslint-disable-next-line
    createProductSuccess: (state, __) => {
      state.creatingProduct = false;
    },
    createProductFail: (state) => {
      state.creatingProduct = false;
    },

    // eslint-disable-next-line
    updateProductRequest: (state, __) => {
      state.updatingProduct = true;
    },
    // eslint-disable-next-line
    updateProductSuccess: (state, __) => {
      state.updatingProduct = false;
    },
    updateProductFail: (state) => {
      state.updatingProduct = false;
    },

    // eslint-disable-next-line
    deleteProductRequest: (state, __) => {
      state.deletingProduct = true;
    },
    // eslint-disable-next-line
    deleteProductSuccess: (state, __) => {
      state.deletingProduct = false;
    },
    deleteProductFail: (state) => {
      state.deletingProduct = false;
    },

    // eslint-disable-next-line
    activeProductRequest: (state, __) => {
      state.activingProduct = true;
    },
    // eslint-disable-next-line
    activeProductSuccess: (state, __) => {
      state.activingProduct = false;
    },
    activeProductFail: (state) => {
      state.activingProduct = false;
    },
  },
});
export const {
  setQueryRequest,
  getProductsRequest,
  getProductsSuccess,
  getProductsFail,
  createProductRequest,
  createProductSuccess,
  createProductFail,
  updateProductRequest,
  updateProductSuccess,
  updateProductFail,
  deleteProductRequest,
  deleteProductSuccess,
  deleteProductFail,
  activeProductRequest,
  activeProductSuccess,
  activeProductFail,
} = productSlice.actions;
export default productSlice.reducer;
