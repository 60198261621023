import { Button, Col, Form, Input, Radio, Row, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect } from "react";
import TextArea from "antd/lib/input/TextArea";

import _ from "lodash";
import { useDispatch } from "react-redux";
import { PayloadType, Customer } from "type";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { RootState } from "store";
import CONSTANT from "constant";
import { createCustomerRequest } from "features/customerSlice";
import { setModal } from "features/modalSlice";

interface Props {
  record?: Customer;
}
function CCustomer({ record }: Props) {
  const dispatch = useDispatch();
  const [form] = useForm();
  const { creatingCustomer } = useSelector(
    (state: RootState) => state.customerReducer
  );
  const modal = useSelector((state: any) => state.modalReducer);

  //validate
  const customerSchema = yup.object().shape({
    name: yup.string().required("Tên khách hàng không được để trống!"),
    phoneNumber: yup
      .string()
      .required("Số điện thoại không được để trống!")
      .test("phone", "Số điện thoại chỉ bao gồm số!", (phone: any): boolean => {
        const reg = /^\d+$/g;
        return reg.test(phone);
      }),
    address: yup.string().required("Địa chỉ không được để trống!"),
    yearOfBirth: yup
      .string()
      .required("Năm sinh không được để trống!")
      .test(
        "yearOfBirth",
        "Năm sinh chỉ bao gồm số!",
        (yearOfBirth: any): boolean => {
          const reg = /^\d+$/g;
          return reg.test(yearOfBirth);
        }
      ),
  });
  const yupSync = {
    async validator({ field }: any, value: any) {
      await customerSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const initValues = {
    name: "",
    phoneNumber: "",
    address: "",
    yearOfBirth: "",
    gender: 0,
  };

  function handleCreate(values: any) {
    const payload: PayloadType = {
      body: { ...values, yearOfBirth: Number(values.yearOfBirth) },
      callback: {
        success() {
          notification.success({
            message: "Tạo mới khách hàng thành công!",
          });
          resetForm();
          if (!!modal.onClose) {
            modal.onClose();
          }
          handleClose();
        },
        failed(errorMessage) {
          notification.error({
            message: "Tạo mới khách hàng thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(createCustomerRequest(payload));
  }

  const resetForm = () => {
    form.resetFields();
  };

  const handleClose = () => {
    dispatch(setModal({ open: false }));
  };

  return (
    <Form
      labelCol={{ span: 16 }}
      layout="vertical"
      initialValues={initValues}
      labelAlign="left"
      form={form}
      onFinish={handleCreate}
    >
      <div className="ant-modal-body">
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              required
              label={"Tên khách hàng"}
              name={"name"}
              rules={[yupSync]}
            >
              <Input placeholder="Nhập vào tên khách hàng" />
            </Form.Item>
            <Form.Item
              required
              label={"Năm sinh"}
              name={"yearOfBirth"}
              rules={[yupSync]}
            >
              <Input placeholder="Nhập vào năm sinh" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              required
              label={"Số điện thoại"}
              name={"phoneNumber"}
              rules={[yupSync]}
            >
              <Input placeholder="Nhập vào số điện thoại" />
            </Form.Item>
            <Form.Item required label={"Giới tính"} name={"gender"}>
              <Radio.Group>
                <Radio value={0}>Nam</Radio>
                <Radio value={1}>Nữ</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          required
          label={"Địa chỉ"}
          name={"address"}
          rules={[yupSync]}
        >
          <TextArea
            autoSize={{ minRows: 3, maxRows: 5 }}
            placeholder="Nhập vào địa chỉ"
          />
        </Form.Item>
      </div>
      <div
        className="ant-modal-footer"
        style={{ padding: "10px 0px", textAlign: "center" }}
      >
        <Button
          onClick={() => {
            handleClose();
          }}
          style={{ width: "48%" }}
        >
          Đóng
        </Button>
        <Button
          type="default"
          style={{
            background: CONSTANT.COLORS.MAIN,
            fontWeight: 400,
            color: CONSTANT.COLORS.MAIN_TEXT_GREY,
            width: "48%",
          }}
          loading={creatingCustomer}
          htmlType="submit"
        >
          {record ? "Xác nhận" : "Lưu lại"}
        </Button>
      </div>
    </Form>
  );
}
export default CCustomer;
