import {
  loginFail,
  loginSuccess,
  registerFail,
  registerSuccess,
} from "features/accountSlice";
import { call, put, takeLatest } from "redux-saga/effects";
import { POST } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
import _ from "lodash";

export function* login(data: ActionType): any {
  const url = ServiceURL.login;
  const { callback, body } = data.payload;
  try {
    const res = yield call(POST, url, body);
    if (res.data?.error?.message) {
      !!callback?.failed && callback.failed(res.data?.error?.message);
      yield put(loginFail());
    } else {
      yield put(loginSuccess(_.get(res, "data.user", {})));
      !!callback?.success && callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    !!callback?.failed && callback.failed(error?.response?.data?.message ?? "");
    yield put(loginFail());
  }
}

export function* accountSaga(): any {
  yield takeLatest("accountSlice/loginRequest", login);
}
