import axios from "axios";
import { BASE_URL } from "services/ServiceURL";

async function refreshTokens(): Promise<any> {
  const url = `${BASE_URL}/auth/refresh-tokens`;
  const refreshToken = localStorage.getItem("refresh-token");
  if (!refreshToken) return "";
  const res = await axios
    .post(url, {
      refreshToken: JSON.parse(refreshToken),
    })
    // eslint-disable-next-line
    .catch((_) => {
      //
    });
  if (res?.data?.refresh?.token) {
    localStorage.setItem(
      "refresh-token",
      JSON.stringify(res?.data?.refresh?.token ?? "")
    );
  }
  return res?.data?.access?.token ?? "";
}

export default refreshTokens;
