/*
 * Import Libraries
 */
import _ from "lodash";
import React from "react";
import { LogoutOutlined } from "@ant-design/icons";
import { Avatar, Button, Layout, Typography } from "antd";
import { Link, useHistory } from "react-router-dom";

import CONSTANT from "constant";

import avatar from "assets/img/avatar_default.jpg";
import logo from "assets/img/logo_white.png";

const { Header } = Layout;
const { Title, Text } = Typography;

const AdminHeader: React.FC = () => {
  const history = useHistory();
  const account = JSON.parse(localStorage.getItem("account") || "{}");

  return (
    <Header
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: CONSTANT.COLORS.MAIN,
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Link to={"/admin/dashboard"}>
          <img alt="logo" className="demo-logo" src={logo} height={40} />
        </Link>
        <Title
          style={{
            color: "#FFFFFF",
            textTransform: "uppercase",
            fontFamily: "Roboto",
            fontWeight: 500,
            marginBottom: 0,
            marginLeft: 12,
          }}
          level={5}
        >
          Bravigo Ottocast
        </Title>
      </div>
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        <Avatar
          src={
            <img src={_.get(account, "avatar", false) || avatar} alt="avatar" />
          }
        />
        <Text
          style={{
            color: "#FFFFFF",
            marginBottom: 0,
            marginLeft: 12,
          }}
        >
          {_.get(account, "name", "")}
        </Text>
        <Button
          style={{
            backgroundColor: "transparent",
            borderWidth: 0,
            boxShadow: "none",
            alignItems: "center",
            alignContent: "center",
            display: "flex",
          }}
          onClick={() => {
            localStorage.clear();
            history.push("/auth/login");
          }}
        >
          <LogoutOutlined style={{ fontSize: "22px", color: "#fff" }} />
        </Button>
      </div>
    </Header>
  );
};

export default AdminHeader;
