/**
 * Màn hình quản trị admin
 */
import { memo, useEffect } from "react";
import {
  Layout,
  Typography,
  Space,
  Button,
  Table,
  Input,
  Popconfirm,
  notification,
} from "antd";
import _ from "lodash";
import { PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { momentTzUnix } from "utils";
import { SearchOutlined } from "@ant-design/icons";

import CONSTANT from "constant";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "store";
import { ModalInfoType, PayloadType } from "type";
import queryString from "query-string";
import {
  getProductsRequest,
  setQueryRequest,
  updateProductRequest,
} from "features/productSlice";
import { setModal } from "features/modalSlice";
import CUProduct from "./components/CUProduct";
import AProduct from "./components/AProduct";

const { Title } = Typography;
const { Content } = Layout;

type Column = {
  deviceName: string;
  seriNumber: string;
  activeTime: number;
  expTime: number;
  isActive: boolean;
};

function Product() {
  const dispatch = useDispatch();
  const { query, products, gettingProducts } = useSelector(
    (state: RootState) => state.productReducer
  );

  const columns: ColumnsType<Column> = [
    {
      title: "Tên thiết bị",
      dataIndex: "deviceName",
      key: "deviceName",
    },
    {
      title: (
        <Input
          placeholder="Số seri"
          value={query.seriNumber}
          onChange={(e) => {
            const currValue = e.target.value;
            dispatch(
              setQueryRequest({
                ...query,
                seriNumber: currValue,
              })
            );
          }}
        />
      ),
      dataIndex: "seriNumber",
      key: "seriNumber",
    },
    {
      title: (
        <Input
          placeholder="Khách hàng"
          value={query.customerName}
          onChange={(e) => {
            const currValue = e.target.value;
            dispatch(
              setQueryRequest({
                ...query,
                customerName: currValue,
              })
            );
          }}
        />
      ),
      dataIndex: "customerId",
      key: "customerId",
      render: (cell) => {
        return (!!cell && cell?.name) ?? "";
      },
    },
    {
      title: "Số điện thoại",
      dataIndex: "customerId",
      key: "customerId",
      render: (cell) => {
        return (!!cell && cell?.phoneNumber) ?? "";
      },
    },

    {
      title: "Ngày kích hoạt",
      dataIndex: "activeTime",
      key: "activeTime",
      render: (cell) => {
        return !!cell && momentTzUnix(cell).format("DD/MM/YYYY");
      },
    },
    {
      title: "Ngày hết hạn",
      dataIndex: "expTime",
      key: "expTime",
      render: (cell) => {
        return !!cell && momentTzUnix(cell).format("DD/MM/YYYY");
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "isActive",
      key: "isActive",
      render: (cell) => {
        return cell ? (
          <span className="active-color">Hoạt động</span>
        ) : (
          <span className="lock-color">Không hoạt động</span>
        );
      },
    },

    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 300,
      render: (_, record: any) => {
        return (
          <Space>
            <a
              onClick={() => {
                const payload: ModalInfoType = {
                  open: true,
                  title: "Cập nhật thông tin thiết bị",
                  content: <CUProduct record={record} />,
                  size: 456,
                  onClose: () => {
                    handleGetProducts();
                  },
                };
                dispatch(setModal(payload));
              }}
            >
              Cập nhật
            </a>
            <a
              onClick={() => {
                const payload: ModalInfoType = {
                  open: true,
                  title: "Kích hoạt thiết bị",
                  content: <AProduct record={record} />,
                  size: 456,
                  onClose: () => {
                    handleGetProducts();
                  },
                };
                dispatch(setModal(payload));
              }}
            >
              Kích hoạt
            </a>
            <Popconfirm
              title={`Bạn chắc chắn muốn RESET thiết bị ${record.deviceName}`}
              okText="Đồng ý"
              okButtonProps={{ size: "small" }}
              cancelButtonProps={{ size: "small" }}
              cancelText="Huỷ"
              onConfirm={() => {
                const payload: PayloadType = {
                  param: record.id,
                  body: { isActive: false },
                  callback: {
                    success() {
                      notification.success({
                        message: "Reset thiết bị thành công!",
                      });
                      handleGetProducts();
                    },
                    failed(errorMessage) {
                      notification.error({
                        message: "Reset thiết bị thất bại!",
                        description: errorMessage,
                      });
                    },
                  },
                };
                dispatch(updateProductRequest(payload));
              }}
            >
              <a href="#">Reset</a>
            </Popconfirm>
            {/* <Popconfirm
              title={"Bạn có chắc chắn muốn xóa thiết bị này không ?"}
              okText={"Đồng ý"}
              cancelText={"Không"}
              onConfirm={() => {
                const id = record?.id ?? "";
                if (!!id) {
                  // const payload: PayloadType = {
                  //   params: id,
                  //   callback: {
                  //     success(values) {
                  //       notification.success({
                  //         message: "Xoá danh mục tên bệnh thành công!",
                  //       });
                  //       getListFood();
                  //     },
                  //     failed(errorMessage) {
                  //       notification.error({
                  //         message: "Xoá danh mục tên bệnh thất bại!",
                  //         description: errorMessage,
                  //       });
                  //     },
                  //   },
                  // };
                  // dispatch(deleteSharedData(payload));
                }
              }}
            >
              <a>Xoá</a>
            </Popconfirm> */}
          </Space>
        );
      },
    },
  ];

  const handleGetProducts = () => {
    const payload: PayloadType = {
      query: queryString.stringify(query, {
        skipEmptyString: true,
        skipNull: true,
      }),
    };
    dispatch(getProductsRequest(payload));
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetProducts();
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [query]);

  return (
    <Content
      className="custom-layout-content background-content-layout"
      style={{
        marginLeft: 24,
        marginTop: 16,
        marginRight: 24,
      }}
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size={"large"}
      >
        <div className="space-between">
          <Title level={4}>Quản trị thiết bị</Title>
          <Space direction="horizontal" size={"middle"}></Space>
        </div>
        <>
          <div
            className="space-between"
            style={{
              marginTop: 12,
              marginBottom: 24,
            }}
          >
            <Title level={5}>Danh sách</Title>
            <Space className="custom-placeholder-select">
              <Button
                type="default"
                onClick={() => {
                  const payload: ModalInfoType = {
                    open: true,
                    title: "Thêm mới thông tin thiết bị",
                    content: <CUProduct />,
                    size: 456,
                    onClose: () => {
                      handleGetProducts();
                    },
                  };
                  dispatch(setModal(payload));
                }}
                style={{
                  background: CONSTANT.COLORS.MAIN,
                  fontWeight: 400,
                  color: CONSTANT.COLORS.MAIN_TEXT_GREY,
                }}
              >
                <PlusOutlined />
                Thêm mới
              </Button>
            </Space>
          </div>
          <Table
            columns={columns}
            rowKey={"id"}
            dataSource={_.get(products, "results", [])}
            loading={gettingProducts}
            // rowClassName={(record: any) =>
            //   // record?.status === "active" ? "table-row-open" : "table-row-close"
            // }
            pagination={{
              total: _.get(products, "totalResults", 0),
              current: query.page,
              pageSize: query.limit,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50"],
              showTotal: (total) => {
                return `Có tất cả ${total} bản ghi`;
              },
              onChange(page, pageSize) {
                dispatch(
                  setQueryRequest({
                    ...query,
                    page,
                    limit: pageSize,
                  })
                );
              },
            }}
            scroll={{ x: 1300 }}
          />
        </>
      </Space>
    </Content>
  );
}

export default memo(Product);
