/*
 * Import Libraries
 */
import React, { useState, memo } from "react";
import { Button, Layout, Menu, MenuProps } from "antd";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

import CONSTANT from "constant";

const { Sider } = Layout;

/*
 * Import data
 */
import routes from "routes";
type MenuItem = Required<any>["items"][string];
let rootSubmenuKeys: any[] = [];

/*
 * function get routes
 */

function getItem(
  label: React.ReactNode,
  key?: string | null,
  icon?: React.ReactNode,
  children?: MenuItem[] | null
): MenuItem {
  if (!key?.includes("/")) {
    return {
      label,
      key,
      icon,
      children,
    } as MenuItem;
  }
  return {
    label: (
      <Link to={key ?? ""} color={CONSTANT.COLORS.MAIN}>
        {label}
      </Link>
    ),
    key,
    icon,
    children,
  } as MenuItem;
}

function getRoutes(routeGroup: any[]) {
  return routeGroup.map((route: any) => {
    let r = [];
    if (route?.hidden) {
      return null;
    }
    if (route?.views) {
      rootSubmenuKeys.push(route?.state ?? "");
      r = route.views.map((children: any) => {
        return getItem(
          children?.name ?? "",
          children?.layout + children?.path,
          null,
          null
        );
      });
      return getItem(
        route?.name ?? "",
        route?.state ?? "",
        route?.icon ?? "",
        r
      );
    }
    return getItem(
      route?.name ?? "",
      route?.layout + route?.path,
      route?.icon ?? "",
      null
    );
  });
}

function getCurrentSelectedKeys(routesArr: any[], pathname: any) {
  let routeTemp = null;
  const routeActive = routesArr.find((item) => {
    if (item.collapse && item.state && item.views) {
      routeTemp = [item.state];
      const result = getCurrentSelectedKeys(item.views, pathname);
      if (result.length > 0) {
        routeTemp.unshift(...result);
        return true;
      }
      routeTemp = null;
      return false;
    }
    return (item?.layout ?? "") + item.path === pathname;
  });
  if (!!routeTemp) {
    return routeTemp;
  }
  if (!!routeActive?.parent) {
    return [routeActive.parent];
  }
  if (!!routeActive) return [(routeActive?.layout ?? "") + routeActive?.path];
  return [];
}

/*
 * View Sidebar
 */

const SideBar = () => {
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);
  const routeActive = getCurrentSelectedKeys(
    routes.routesSM,
    history.location.pathname
  );
  const [openKeys, setOpenKeys] = useState<string[]>(routeActive);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <>
      <Sider
        width={256}
        style={{
          backgroundColor: "white",
          position: "relative",
        }}
        collapsed={collapsed}
      >
        <Menu
          mode="inline"
          className="custom-submenu-scroll"
          style={{ flex: 1, borderRight: 0 }}
          triggerSubMenuAction="hover"
          multiple={false}
          items={getRoutes(routes.routesSM)}
          selectedKeys={openKeys}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          onClick={({ __, keyPath }: any) => {
            setOpenKeys(keyPath);
          }}
        />
        <Button
          type="text"
          onClick={toggleCollapsed}
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            borderTop: "1px solid #eee",
            textAlign: "left",
          }}
        >
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
      </Sider>
    </>
  );
};

export default memo(SideBar);
