import { createSlice } from "@reduxjs/toolkit";

interface Query {
  page: number;
  limit: number;
}
interface InitState {
  gettingCustomers: boolean;
  creatingCustomer: boolean;
  updatingCustomer: boolean;
  deletingCustomer: boolean;
  activingCustomer: boolean;
  customers: object | {};
  query: Query;
}

const initialState = {
  gettingCustomers: false,
  creatingCustomer: false,
  updatingCustomer: false,
  deletingCustomer: false,
  activingCustomer: false,
  customers: {},
  query: { page: 1, limit: 10 },
} as InitState;

export const customerSlice = createSlice({
  name: "customerSlice",
  initialState,
  reducers: {
    setQueryRequest: (state, action) => {
      state.query = action.payload;
    },
    // eslint-disable-next-line
    getCustomersRequest: (state, __) => {
      state.gettingCustomers = true;
    },
    getCustomersSuccess: (state, action) => {
      state.gettingCustomers = false;
      state.customers = action.payload;
    },
    getCustomersFail: (state) => {
      state.gettingCustomers = false;
    },
    // eslint-disable-next-line
    createCustomerRequest: (state, __) => {
      state.creatingCustomer = true;
    },
    // eslint-disable-next-line
    createCustomerSuccess: (state, __) => {
      state.creatingCustomer = false;
    },
    createCustomerFail: (state) => {
      state.creatingCustomer = false;
    },

    // eslint-disable-next-line
    updateCustomerRequest: (state, __) => {
      state.updatingCustomer = true;
    },
    // eslint-disable-next-line
    updateCustomerSuccess: (state, __) => {
      state.updatingCustomer = false;
    },
    updateCustomerFail: (state) => {
      state.updatingCustomer = false;
    },

    // eslint-disable-next-line
    deleteCustomerRequest: (state, __) => {
      state.deletingCustomer = true;
    },
    // eslint-disable-next-line
    deleteCustomerSuccess: (state, __) => {
      state.deletingCustomer = false;
    },
    deleteCustomerFail: (state) => {
      state.deletingCustomer = false;
    },
  },
});
export const {
  setQueryRequest,
  getCustomersRequest,
  getCustomersSuccess,
  getCustomersFail,
  createCustomerRequest,
  createCustomerSuccess,
  createCustomerFail,
  updateCustomerRequest,
  updateCustomerSuccess,
  updateCustomerFail,
  deleteCustomerRequest,
  deleteCustomerSuccess,
  deleteCustomerFail,
} = customerSlice.actions;
export default customerSlice.reducer;
