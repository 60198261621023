import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
  Typography,
  notification,
} from "antd";
import { useHistory } from "react-router";
import * as yup from "yup";
import _ from "lodash";
import { MailOutlined, KeyOutlined } from "@ant-design/icons";

import { PayloadType } from "type";
import { useDispatch } from "react-redux";
import CONSTANT from "constant";

import logo from "assets/img/logo_white.png";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useState } from "react";
import { loginRequest } from "features/accountSlice";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

const { Text } = Typography;

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { loginning } = useSelector((state: RootState) => state.accountReducer);
  const [isRemember, setIsRemember] = useState<boolean>(
    JSON.parse(localStorage.getItem("isRemember") ?? "true")
  );
  const auth = {
    email: atob(JSON.parse(localStorage.getItem("email") ?? '""')),
    password: atob(JSON.parse(localStorage.getItem("password") ?? '""')),
  };
  let loginSchema = yup.object().shape({
    email: yup
      .string()
      .email("Email không đúng định dạng!")
      .required("Email không được để trống!"),
    password: yup.string().required("Mật khẩu không được để trống!"),
  });
  const yupSync = {
    async validator({ field }: any, value: any) {
      await loginSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const handleSubmit = async (values: {
    email: string;
    password: string;
  }): Promise<void> => {
    const { email, password } = values;
    if (isRemember) {
      localStorage.setItem("email", JSON.stringify(btoa(email)));
      localStorage.setItem("password", JSON.stringify(btoa(password)));
    } else {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }
    localStorage.setItem("isRemember", JSON.stringify(isRemember));

    const payload: PayloadType = {
      body: {
        email,
        password,
      },
      callback: {
        success(data) {
          const { tokens = null, user = null } = data;
          if (tokens === null || user === null) return;
          localStorage.setItem("account", JSON.stringify(user));
          localStorage.setItem(
            "access-token",
            JSON.stringify(_.get(tokens, "access.token", ""))
          );
          localStorage.setItem(
            "refresh-token",
            JSON.stringify(_.get(tokens, "refresh.token", ""))
          );
          history.push("/admin/dashboard");
        },
        failed() {
          notification.error({
            message: "Thông tin tài khoản hoặc mật khẩu không chính xác!",
          });
        },
      },
    };
    dispatch(loginRequest(payload));
  };
  const handleChecked = (event: CheckboxChangeEvent): void => {
    const { checked } = event.target;
    setIsRemember(checked);
  };
  return (
    <div
      className="bgLogin"
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: CONSTANT.COLORS.MAIN,
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: 1,
          width: "100%",
          height: "100%",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          zIndex: 2,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Row>
          <Col
            span={24}
            className="d-flex align-items-center"
            style={{
              padding: " 0px 31px",
              background: "rgba(0, 0, 0, 0.20)",
            }}
          >
            <Space
              className="justify"
              direction="vertical"
              align="center"
              size={"small"}
              style={{}}
            >
              <img
                src={logo}
                alt="logo"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: 446,
                }}
              />

              <Card
                size="default"
                style={{
                  width: 500,
                  paddingLeft: 12,
                  paddingRight: 12,
                  // paddingTop: 0,
                  borderRadius: 24,
                  marginTop: 32,
                }}
              >
                <Form
                  name="normal_login"
                  className="login-form"
                  initialValues={{ remember: true, size: "large", ...auth }}
                  size={"large"}
                  onFinish={handleSubmit}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: 16,
                    }}
                  >
                    <Space align="center">
                      <Text type="secondary" style={{ marginTop: 0 }}>
                        Vui lòng đăng nhập để sử dụng các chức năng quản lý
                      </Text>
                    </Space>
                  </div>
                  <Form.Item name="email" rules={[yupSync]}>
                    <Input
                      placeholder="Nhập tên tài khoản"
                      prefix={<MailOutlined style={{ fontSize: 18 }} />}
                      autoComplete="off"
                    />
                  </Form.Item>
                  <Form.Item name="password" rules={[yupSync]}>
                    <Input.Password
                      // prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Nhập mật khẩu"
                      visibilityToggle={{
                        visible: false,
                      }}
                      autoComplete="off"
                      prefix={<KeyOutlined style={{ fontSize: 18 }} />}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Space
                      direction="horizontal"
                      style={{
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <Form.Item
                        name="remember"
                        valuePropName="checked"
                        noStyle
                      >
                        <Checkbox
                          name="isRemember"
                          checked={isRemember}
                          onChange={handleChecked}
                        >
                          Ghi nhớ đăng nhập
                        </Checkbox>
                      </Form.Item>

                      {/* <a
                        className="login-form-forgot"
                        href="/auth/reset-password"
                        style={{
                          textAlign: "right",
                          fontSize: 15,
                          fontStyle: "italic",
                          fontWeight: "400",
                          textDecoration: "underline",
                        }}
                      >
                        Quên mật khẩu
                      </a> */}
                    </Space>
                  </Form.Item>

                  <Form.Item
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      style={{
                        width: "100%",
                        background: CONSTANT.COLORS.MAIN,
                      }}
                      loading={loginning}
                    >
                      Đăng nhập
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Space>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Login;
