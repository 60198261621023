import { createSlice } from "@reduxjs/toolkit";

interface Query {
  page: number;
  limit: number;
}

interface DashboardInfo {
  numberDevice: number;
  numberDeviceActived: number;
  numberDeviceActivedInMonth: number;
  numberCreatedAtInMont: number;
  deviceCreatedInYears: {
    datas: number[];
    labels: string[];
  };
  realTimeChartsData: {
    datas: number[];
    pages: { pathname: string; views: number }[];
  };
}

interface InitState {
  gettingDashboard: boolean;
  dashboard: DashboardInfo | null;
  query: Query;
}

const initialState = {
  gettingDashboard: false,
  dashboard: null,
  query: { page: 1, limit: 10 },
} as InitState;

export const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {
    setQueryRequest: (state, action) => {
      state.query = action.payload;
    },
    // eslint-disable-next-line
    getDashboardRequest: (state, __) => {
      state.gettingDashboard = true;
    },
    getDashboardSuccess: (state, action) => {
      state.gettingDashboard = false;
      state.dashboard = action.payload;
    },
    getDashboardFail: (state) => {
      state.gettingDashboard = false;
    },
  },
});
export const {
  setQueryRequest,
  getDashboardRequest,
  getDashboardSuccess,
  getDashboardFail,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
