import {
  activeProductFail,
  activeProductSuccess,
  createProductFail,
  createProductSuccess,
  deleteProductFail,
  deleteProductSuccess,
  getProductsFail,
  getProductsSuccess,
  updateProductFail,
  updateProductSuccess,
} from "features/productSlice";
import { call, put, takeLatest } from "redux-saga/effects";
import { DELETE, GET, PATCH, POST } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
import _ from "lodash";

export function* getProducts(data: ActionType): any {
  const { callback, query } = data.payload;
  const url = `${ServiceURL.devices}?${query}`;
  try {
    const res = yield call(GET, url);
    if (res.data?.error?.message) {
      callback?.failed && callback.failed(res.data?.error?.message);
      yield put(getProductsFail());
    } else {
      yield put(getProductsSuccess(_.get(res, "data", {})));
      !!callback?.success && callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    callback?.failed && callback.failed(error?.response?.data?.message ?? "");
    yield put(getProductsFail());
  }
}

export function* createProduct(data: ActionType): any {
  const { callback, query, body } = data.payload;
  const url = `${ServiceURL.devices}?${query}`;
  try {
    const res = yield call(POST, url, body);
    if (res.data?.error?.message) {
      callback?.failed && callback.failed(res.data?.error?.message);
      yield put(createProductFail());
    } else {
      yield put(createProductSuccess(_.get(res, "data", {})));
      !!callback?.success && callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    callback?.failed && callback.failed(error?.response?.data?.message ?? "");
    yield put(createProductFail());
  }
}

export function* updateProduct(data: ActionType): any {
  const { callback, query, body, param } = data.payload;
  const url = `${ServiceURL.devices}/${param}?${query}`;
  try {
    const res = yield call(PATCH, url, body);
    if (res.data?.error?.message) {
      callback?.failed && callback.failed(res.data?.error?.message);
      yield put(updateProductFail());
    } else {
      yield put(updateProductSuccess(_.get(res, "data", {})));
      !!callback?.success && callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    callback?.failed && callback.failed(error?.response?.data?.message ?? "");
    yield put(updateProductFail());
  }
}

export function* deleteProduct(data: ActionType): any {
  const { callback, query, param } = data.payload;
  const url = `${ServiceURL.devices}/${param}?${query}`;
  try {
    const res = yield call(DELETE, url);
    if (res.data?.error?.message) {
      callback?.failed && callback.failed(res.data?.error?.message);
      yield put(deleteProductFail());
    } else {
      yield put(deleteProductSuccess(_.get(res, "data", {})));
      !!callback?.success && callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    callback?.failed && callback.failed(error?.response?.data?.message ?? "");
    yield put(deleteProductFail());
  }
}

export function* activeProduct(data: ActionType): any {
  const { callback, query, body } = data.payload;
  const url = `${ServiceURL.devices}/active?${query}`;
  try {
    const res = yield call(POST, url, body);
    if (res.data?.error?.message) {
      callback?.failed && callback.failed(res.data?.error?.message);
      yield put(activeProductFail());
    } else {
      yield put(activeProductSuccess(_.get(res, "data", {})));
      !!callback?.success && callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    callback?.failed && callback.failed(error?.response?.data?.message ?? "");
    yield put(activeProductFail());
  }
}

export function* productSaga(): any {
  yield takeLatest("productSlice/getProductsRequest", getProducts);
  yield takeLatest("productSlice/createProductRequest", createProduct);
  yield takeLatest("productSlice/updateProductRequest", updateProduct);
  yield takeLatest("productSlice/deleteProductRequest", deleteProduct);
  yield takeLatest("productSlice/activeProductRequest", activeProduct);
}
