import { getDashboardFail, getDashboardSuccess } from "features/dashboardSlice";
import { call, put, takeLatest } from "redux-saga/effects";
import { GET } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
import _ from "lodash";

export function* getDashboard(data: ActionType): any {
  const { callback, query } = data.payload;
  const url = `${ServiceURL.dashboards}?${query}`;
  try {
    const res = yield call(GET, url);
    if (res.data?.error?.message) {
      callback?.failed && callback.failed(res.data?.error?.message);
      yield put(getDashboardFail());
    } else {
      yield put(getDashboardSuccess(_.get(res, "data", {})));
      !!callback?.success && callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    callback?.failed && callback.failed(error?.response?.data?.message ?? "");
    yield put(getDashboardFail());
  }
}

export function* dashboardSaga(): any {
  yield takeLatest("dashboardSlice/getDashboardRequest", getDashboard);
}
