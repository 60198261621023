import ReactApexChart from "react-apexcharts";
import { Typography } from "antd";
import { ApexOptions } from "apexcharts";

type Props = {
  deviceCreatedInYears: null | {
    datas: number[];
    labels: string[];
  };
};

function LineChart(props: Props) {
  const { Title } = Typography;

  const series: ApexAxisChartSeries | ApexNonAxisChartSeries = [
    {
      name: "Mã",
      data: props.deviceCreatedInYears?.datas ?? [],
    },
  ];

  const options: ApexOptions = {
    chart: {
      width: "100%",
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },

    legend: {
      show: false,
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },

    yaxis: {
      labels: {
        style: {
          fontSize: "14px",
          fontWeight: 600,
          colors: ["#8c8c8c"],
        },
      },
    },

    xaxis: {
      labels: {
        style: {
          fontSize: "14px",
          fontWeight: 600,
          colors: [
            "#8c8c8c",
            "#8c8c8c",
            "#8c8c8c",
            "#8c8c8c",
            "#8c8c8c",
            "#8c8c8c",
            "#8c8c8c",
            "#8c8c8c",
            "#8c8c8c",
          ],
        },
      },
      categories: props.deviceCreatedInYears?.labels ?? [],
    },

    tooltip: {
      y: {
        formatter: function (val: number) {
          return val.toString();
        },
      },
    },
  };
  return (
    <>
      <div className="linechart">
        <div>
          <Title level={5}>Mã đã tạo trên năm</Title>
        </div>
        <div className="sales">
          <ul>
            {/* <li>{<MinusOutlined />} Traffic</li>
            <li>{<MinusOutlined />} Sales</li> */}
          </ul>
        </div>
      </div>

      <ReactApexChart
        className="full-width"
        options={options as any}
        series={series}
        type="area"
        height={350}
        width={"100%"}
      />
    </>
  );
}

export default LineChart;
