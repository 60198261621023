import { all, fork } from "redux-saga/effects";
import { accountSaga } from "sagas/accountSaga";
import { productSaga } from "sagas/productSaga";
import { customerSaga } from "sagas/customerSaga";
import { dashboardSaga } from "sagas/dashboardSaga";

function* rootSaga() {
  yield all([fork(accountSaga)]);
  yield all([fork(productSaga)]);
  yield all([fork(customerSaga)]);
  yield all([fork(dashboardSaga)]);
}
export default rootSaga;
