import momentTimezone from "moment-timezone";

export const momentTz = (date?: string) => {
  if (date) return momentTimezone(date).tz("Asia/Ho_Chi_Minh");
  return momentTimezone().tz("Asia/Ho_Chi_Minh");
};

export const momentTzUnix = (timeUnix: number) => {
  return momentTimezone.unix(timeUnix).tz("Asia/Ho_Chi_Minh");
};

/**
 * Đóng modal
 * @param dispatch useDispatch()
 * @param setModal features/modalSlice
 */
export function closeModal(
  dispatch: (param: any) => void,
  setModal: (values: any) => {}
) {
  dispatch(
    setModal({
      open: false,
      content: "",
      title: "",
      dataUpdate: null,
      onClose: undefined,
    })
  );
}
