import { Button, DatePicker, Form, Input, Radio, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import TextArea from "antd/lib/input/TextArea";

import _ from "lodash";
import { useDispatch } from "react-redux";
import { PayloadType, Product } from "type";
import * as yup from "yup";
import queryString from "query-string";
import NumberFormat from "react-currency-format";
import { momentTz, momentTzUnix } from "utils";
import { useSelector } from "react-redux";
import { RootState } from "store";
import CONSTANT from "constant";
import {
  createProductRequest,
  updateProductRequest,
} from "features/productSlice";
import { setModal } from "features/modalSlice";

interface Props {
  record?: Product;
}
function CUProduct({ record }: Props) {
  const dispatch = useDispatch();
  const [form] = useForm();
  const { creatingProduct, updatingProduct } = useSelector(
    (state: RootState) => state.productReducer
  );
  const modal = useSelector((state: any) => state.modalReducer);

  //validate
  const productSchema = yup.object().shape({
    deviceName: yup.string().required("Tên thiết bị không được để trống!"),
    seriNumber: yup.string().required("Số seri không được để trống!"),
    // customerId: yup.string().required("Khách hàng không được để trống!"),
    // expTime: yup.date().required("Hạn sử dụng không được để trống!"),
  });
  const yupSync = {
    async validator({ field }: any, value: any) {
      await productSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const initValues = {
    deviceName: "",
    seriNumber: "",
    // customerId: null,
    // expTime: momentTz(),
    // isActive: true,
  };

  function handleCreate(values: any) {
    const payload: PayloadType = {
      body: values,
      callback: {
        success() {
          notification.success({
            message: "Tạo mới thiết bị thành công!",
          });
          form.resetFields();
          if (!!modal.onClose) {
            modal.onClose();
          }
          handleClose();
        },
        failed(errorMessage) {
          notification.error({
            message: "Tạo mới thiết bị thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(createProductRequest(payload));
  }

  function handleUpdate(values: any) {
    if (!record?.id) return;
    const payload: PayloadType = {
      param: record.id,
      body: values,
      callback: {
        success() {
          notification.success({
            message: "Cập nhật thiết bị thành công!",
          });
          form.resetFields();
          if (!!modal.onClose) {
            modal.onClose();
          }
          handleClose();
        },
        failed(errorMessage) {
          notification.error({
            message: "Cập nhật thiết bị thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(updateProductRequest(payload));
  }

  const resetForm = () => {
    form.resetFields();
  };

  const handleClose = () => {
    dispatch(setModal({ open: false }));
  };

  useEffect(() => {
    if (!record) {
      resetForm();
    } else {
      form.setFieldsValue({
        deviceName: record.deviceName,
        seriNumber: record.seriNumber,
      });
    }
  }, [record]);

  return (
    <Form
      labelCol={{ span: 16 }}
      layout="vertical"
      initialValues={initValues}
      labelAlign="left"
      form={form}
      onFinish={!record ? handleCreate : handleUpdate}
    >
      <div className="ant-modal-body">
        <Form.Item
          required
          label={"Tên thiết bị"}
          name={"deviceName"}
          rules={[yupSync]}
        >
          <Input placeholder="Nhập vào tên thiết bị" />
        </Form.Item>

        <Form.Item
          required
          label={"Số siri"}
          name={"seriNumber"}
          rules={[yupSync]}
        >
          <Input placeholder="Nhập vào số seri" />
        </Form.Item>

        {/* <Form.Item
          required
          label={"Hạn sử dụng"}
          name={"expTime"}
          rules={[yupSync]}
        >
          <DatePicker
            format={"DD/MM/YYYY"}
            placeholder="Chọn ngày bắt đầu"
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item label={"Trạng thái"} name={"isActive"}>
          <Radio.Group>
            <Radio value={true}>Hoạt động</Radio>
            <Radio value={false}>Khóa</Radio>
          </Radio.Group>
        </Form.Item> */}
      </div>
      <div
        className="ant-modal-footer"
        style={{ padding: "10px 0px", textAlign: "center" }}
      >
        <Button
          onClick={() => {
            handleClose();
          }}
          style={{ width: "48%" }}
        >
          Đóng
        </Button>
        <Button
          type="default"
          style={{
            background: CONSTANT.COLORS.MAIN,
            fontWeight: 400,
            color: CONSTANT.COLORS.MAIN_TEXT_GREY,
            width: "48%",
          }}
          loading={creatingProduct || updatingProduct}
          htmlType="submit"
        >
          {record ? "Xác nhận" : "Lưu lại"}
        </Button>
      </div>
    </Form>
  );
}
export default CUProduct;
