import {
  createCustomerFail,
  createCustomerSuccess,
  deleteCustomerFail,
  deleteCustomerSuccess,
  getCustomersFail,
  getCustomersSuccess,
  updateCustomerFail,
  updateCustomerSuccess,
} from "features/customerSlice";
import { call, put, takeLatest } from "redux-saga/effects";
import { DELETE, GET, PATCH, POST } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
import _ from "lodash";

export function* getCustomers(data: ActionType): any {
  const { callback, query } = data.payload;
  const url = `${ServiceURL.customers}?${query}`;
  try {
    const res = yield call(GET, url);
    if (res.data?.error?.message) {
      callback?.failed && callback.failed(res.data?.error?.message);
      yield put(getCustomersFail());
    } else {
      yield put(getCustomersSuccess(_.get(res, "data", {})));
      !!callback?.success && callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    callback?.failed && callback.failed(error?.response?.data?.message ?? "");
    yield put(getCustomersFail());
  }
}

export function* createCustomer(data: ActionType): any {
  const { callback, query, body } = data.payload;
  const url = `${ServiceURL.customers}?${query}`;
  try {
    const res = yield call(POST, url, body);
    if (res.data?.error?.message) {
      callback?.failed && callback.failed(res.data?.error?.message);
      yield put(createCustomerFail());
    } else {
      yield put(createCustomerSuccess(_.get(res, "data", {})));
      !!callback?.success && callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    callback?.failed && callback.failed(error?.response?.data?.message ?? "");
    yield put(createCustomerFail());
  }
}

export function* updateCustomer(data: ActionType): any {
  const { callback, query, body, param } = data.payload;
  const url = `${ServiceURL.customers}/${param}?${query}`;
  try {
    const res = yield call(PATCH, url, body);
    if (res.data?.error?.message) {
      callback?.failed && callback.failed(res.data?.error?.message);
      yield put(updateCustomerFail());
    } else {
      yield put(updateCustomerSuccess(_.get(res, "data", {})));
      !!callback?.success && callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    callback?.failed && callback.failed(error?.response?.data?.message ?? "");
    yield put(updateCustomerFail());
  }
}

export function* deleteCustomer(data: ActionType): any {
  const { callback, query, param } = data.payload;
  const url = `${ServiceURL.customers}/${param}?${query}`;
  try {
    const res = yield call(DELETE, url);
    if (res.data?.error?.message) {
      callback?.failed && callback.failed(res.data?.error?.message);
      yield put(deleteCustomerFail());
    } else {
      yield put(deleteCustomerSuccess(_.get(res, "data", {})));
      !!callback?.success && callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    callback?.failed && callback.failed(error?.response?.data?.message ?? "");
    yield put(deleteCustomerFail());
  }
}

export function* customerSaga(): any {
  yield takeLatest("customerSlice/getCustomersRequest", getCustomers);
  yield takeLatest("customerSlice/createCustomerRequest", createCustomer);
  yield takeLatest("customerSlice/updateCustomerRequest", updateCustomer);
  yield takeLatest("customerSlice/deleteCustomerRequest", deleteCustomer);
}
